<template>
  <div
    v-if="(planCode || minuteAddOn || giftCardSelected || daysSelected)"
    id="signup-order-summary"
  >
    <div v-if="orderSummary.length">
      <FormSection
        title="Order Summary"
        class="signup-order-summary-section"
      >
        <SignupOrderSummaryItem
          v-for="(item, index) in orderSummary"
          :key="index"
          :description="item.description"
          :note="item.note"
          :price="item.chargeInCents"
          :currency="item.currency"
        />
      </FormSection>
      <SignupOrderSummaryItem
        :price="orderTotal"
        :total="true"
        :currency="orderTotalCurrency"
        description="Today's Total"
      />
    </div>
    <div v-if="resellerOrderSummary.length">
      <FormSection
        title="Reseller Order Summary"
        class="signup-order-summary-section"
      >
        <SignupOrderSummaryItem
          v-for="(item, index) in resellerOrderSummary"
          :key="index"
          :description="item.description"
          :note="item.note"
          :price="item.chargeInCents"
          :currency="item.currency"
        />
      </FormSection>
      <SignupOrderSummaryItem
        :price="resellerOrderTotal"
        :total="true"
        :currency="resellerOrderTotalCurrency"
        description="Today's Total"
      />
    </div>
  </div>
  <div v-else>
    <b-spinner
      variant="primary"
      label="loading"
      class="spinner"
      aria-hidden
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import FormSection from '../../Form/FormSection.vue';
import SignupOrderSummaryItem from './SignupOrderSummaryItem.vue';

export default {
  name: 'SignupOrderSummary',
  components: {
    FormSection,
    SignupOrderSummaryItem,
  },
  computed: {
    ...mapGetters([
      'planCode',
      'minuteAddOn',
      'giftCardSelected',
      'daysSelected',
      'orderSummary',
      'orderTotal',
      'orderTotalCurrency',
      'resellerOrderSummary',
      'resellerOrderTotal',
      'resellerOrderTotalCurrency',
    ]),
  },
};
</script>

<style lang='scss' scoped>

#signup-order-summary {
  margin-bottom: 25px;
  .signup-order-summary-section {
    padding: 0 20px;
    margin-bottom: 0px;
  }
}
.spinner {
  margin: 40px;
}
</style>
