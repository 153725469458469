<template>
  <div :class="['signup-order-summary-item', { 'total': total }]">
    <p
      role="section"
      class="hide-text"
    >
      {{ description }}, {{ displayPrice }}, {{ note }}
    </p>
    <div
      aria-hidden="true"
      class="signup-order-summary-item-left"
    >
      <span class="signup-order-summary-item-title">{{ description }}</span><br>
      <span class="signup-order-summary-item-description"><small>{{ note }}</small></span>
    </div>
    <div
      aria-hidden="true"
      class="signup-order-summary-item-right float-right"
    >
      <span class="signup-order-summary-item-price">{{ displayPrice }}</span><br>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'SignupOrderSummaryItem',
  props: {
    description: {
      type: String,
      required: false,
      default: '',
    },
    note: {
      type: String,
      required: false,
      default: '',
    },
    price: {
      type: Number,
      required: false,
      default: 0,
    },
    currency: {
      type: String,
      required: false,
      default: 'USD',
    },
    total: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    ...mapGetters([
      'plan',
    ]),
    displayPrice() {
      if (!this.price && !this.total) return 'FREE';
      const currencyFormatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: this.currency,
        currencyDisplay: 'symbol',
        minimumFractionDigits: 0,
      });
      return currencyFormatter.format(this.price / 100);
    },
  },
};
</script>

<style lang='scss' scoped>
@import '../../../styles/variables/_colors.scss';

.signup-order-summary-item {
  height: auto;
  width: 100%;
  line-height: 1;
  display: table;
  padding: 20px 0 15px 0;
  border-top: 0.25px solid $aira-color-divider;
  .signup-order-summary-item-left, .signup-order-summary-item-right {
    height: 100%;
    display: table-cell;
    vertical-align: top;
  }
  .signup-order-summary-item-right > span {
    float: right;
  }
  .signup-order-summary-item-description,  .signup-order-summary-item-recurring {
    line-height: 1;
    opacity: 0.5;
  }
}
.hide-text {
  height: 0;
  width: 0;
  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden;
}
</style>
